import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-update-zone',
  templateUrl: './update-zone.component.html',
  styleUrls: ['./update-zone.component.scss']
})
export class UpdateZoneComponent implements OnInit {


  zones: any[] = [];

  produit: any[] = [];
  zone: any;
 
  categories: any[] = [];
  etatPadding: boolean = true;

  constructor(private fb: FormBuilder, private zoneServices: ZoneService, private produitService: ProduitService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getProduit();
    this.getZones();
  }
  
  getZones(){
    this.zoneServices.getZone(this.data.id).subscribe(res => {
      this.zone = res;
      this.initialiseForms();
      console.log("ma zone",this.zone);
    
    })
  }
  getProduit(){
    this.produitService.getProduits().subscribe(res => {
      this.produit = res;
      
    })
  }

  

  zoneForm = this.fb.group({

    
    ville: ['', []],
    commune: ['', []],
    localite: ['', [Validators.required]],
    
  });

  

  onSubmit(){
    this.zoneServices.updateZone(this.data.id,this.zoneForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = false;

      });
    
  }

  private initialiseForms(){
    this.zoneForm.patchValue({
      ville: this.zone[0].ville ? this.zone[0].ville : '',
      commune: this.zone[0].commune ? this.zone[0].commune : '',
      localite: this.zone[0].localite ? this.zone[0].localite : '',
    })
  }


 
  
  
  get ville(){
    return this.zoneForm.get('ville');
  }
  get commune(){
    return this.zoneForm.get('commune');
  }
  get localite(){
    return this.zoneForm.get('localite');
  }
 


}
