import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-add-zone',
  templateUrl: './add-zone.component.html',
  styleUrls: ['./add-zone.component.scss']
})
export class AddZoneComponent implements OnInit {

  etatPadding: boolean = true;
  produits: any[] = [];
  constructor(public dialogRef: MatDialogRef<AddZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private produitService: ProduitService,private fb: FormBuilder, private zoneService: ZoneService, private _snackBar: SnackBarService) { }

  zoneForm = this.fb.group({
   
    ville: ['', []],
    commune: ['', []],
    localite: ['', [Validators.required]],
  
   
  });

  ngOnInit(): void {
   
  }

  onSubmit(){
    this.etatPadding = false;

    this.zoneService.addZone(this.zoneForm.value).subscribe(res => {
      this._snackBar.openSnackBar("Zone Ajouter!!", "Fermer");
     
      this.etatPadding = true;
      
      this.dialogRef.close();
    })
  }
}
