import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddPlageComponent } from 'src/app/add-plage/add-plage.component';
import { AddStockComponent } from 'src/app/add-stock/add-stock.component';
import { AddZoneComponent } from 'src/app/add-zone/add-zone.component';
import { DeleteStockComponent } from 'src/app/delete-stock/delete-stock.component';
import { DeleteZoneComponent } from 'src/app/delete-zone/delete-zone.component';
import { CommandeService } from 'src/app/services/commande.service';
import { ZoneService } from 'src/app/services/zone.service';
import { UpdateStockComponent } from 'src/app/update-stock/update-stock.component';
import { UpdateZoneComponent } from 'src/app/update-zone/update-zone.component';
import { ViewPlageComponent } from 'src/app/view-plage/view-plage.component';

@Component({
  selector: 'app-all-stock',
  templateUrl: './all-stock.component.html',
  styleUrls: ['./all-stock.component.scss']
})
export class AllStockComponent implements OnInit {
  subscription: Subscription;
  displayedColumns: string[] = ['fournisseur', 'produit', 'quantite','action','a'];
    constructor(private zoneService:ZoneService,private stockService:CommandeService,private dialog: MatDialog){}
 

  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
      this.getAllOrders();
    });
  
  }

  getAllOrders() {
    this.stockService.allStock().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      return data
    })}


    addZone(){
      this.dialog.open(AddZoneComponent, {
        width: '400px',
        height: '380px',
       
      })
    }
    addStock(){
      this.dialog.open(AddStockComponent, {
        width: '400px',
        height: '400px',
       
      })
    }
    viewPlage(id){
      this.dialog.open(ViewPlageComponent, {
        data: {id: id}
       
      })
    }
    updateStock(id){
      this.dialog.open(UpdateStockComponent, {
        width: '400px',
        height: '380px',
        data: {id: id}
      })
    }
    deleteStock(id){
      this.dialog.open(DeleteStockComponent, {
       
        data: {id: id}
      })
    }

}

export interface PeriodicElement {
  fournisseur: string;
  produit: string;
  quantite: string;
  prix: string;
  action: string;
  a: string;
 
 
 
}


