import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddProduitComponent } from 'src/app/add-produit/add-produit.component';


import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { DeleteCategorieComponent } from 'src/app/delete-categorie/delete-categorie.component';
import { PrintService } from 'src/app/services/print.service';
import { ProduitService } from 'src/app/services/produit.service';
import { AddCategorieComponent } from '../../add-categorie/add-categorie.component';
import { Categorie } from './Categorie.model';
import { UpdateCategorieComponent } from '../../update-categorie/update-categorie.component';




@Component({
  selector: 'app-all-categorie',
  templateUrl: './all-categorie.component.html',
  styleUrls: ['./all-categorie.component.scss']
})
export class AllCategorieComponent implements OnInit {
// produits: any[] = []; 
public categories: Categorie[]
public settings: Settings;
constructor(public print: PrintService, private dialog: MatDialog,public appSettings:AppSettings,private produitService:ProduitService) {
 this.settings = this.appSettings.settings; 
}

ngOnInit(): void {
 this.getProduits();
}
getProduits(){
 this.produitService.getCategorie().subscribe(res => {
   this.categories = res;
  console.log("data", this.categories);
 });
}
addCategorie(){
  this.dialog.open(AddCategorieComponent, {
  
   
  })
}
deleteCategorie(id){
  this.dialog.open(DeleteCategorieComponent, {
    data: {id: id}
   
  })
}
upadteCategorie(id){
  this.dialog.open(UpdateCategorieComponent, {
    data: {id: id}
   
  })
}
}
