import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddPlageComponent } from '../add-plage/add-plage.component';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-add-chauffeur',
  templateUrl: './add-chauffeur.component.html',
  styleUrls: ['./add-chauffeur.component.scss']
})
export class AddChauffeurComponent implements OnInit {

 
  constructor(public dialogRef: MatDialogRef<AddPlageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private produitService: ProduitService,private fb: FormBuilder, private zoneService: ZoneService, private _snackBar: SnackBarService) { }
  etatPadding: boolean = true;
  produits: any[] = [];
  zone: any;
  zoneForm = this.fb.group({
    nom: ['', []],
    prenom: ['', []],
    telephone: ['', []],
    ville: ['', []],
    commune: ['', []],
   
  });

  ngOnInit(): void {
  
  
  }

  onSubmit(){
    this.etatPadding = false;
    

    this.zoneService.addChauffeur(this.zoneForm.value).subscribe(res => {
      this._snackBar.openSnackBar("Chauffeur Ajouter!!", "Fermer");
     console.log("chauffeur",this.zoneForm.value);
      this.etatPadding = true;
      
      this.dialogRef.close();
    })
   
  
  }

  get nom(){
    return this.zoneForm.get('prenom');
  }
  get prenom(){
    return this.zoneForm.get('prenom');
  }
  get telephone(){
    return this.zoneForm.get('telephone');
  }
  get ville(){
    return this.zoneForm.get('ville');
  }
  get commune(){
    return this.zoneForm.get('commune');
  }

 

}
